(function() {

    var form1 = document.querySelector('#form1');
    var shareBox = document.querySelector('#shareboxes');
    if (shareBox) {
        var shareBoxDisplayStyle = window.getComputedStyle(shareBox).getPropertyValue('display');
    }
    var inputField1 = document.querySelector('#fullUrlField1');
    var shortenBtn1 = document.querySelector('#shortenBtn1');
    var errorBlock = document.querySelector('#error-message');
    var httpsErrorBlock = document.querySelector('#https-error-message');
    var printBtn = document.querySelector('#printQRCodeBtn');

    const qrLinkE = document.querySelector('.qrLink');
    const qrLinkContainerE = document.querySelector('.qrLinkContainer');

    const socialIconContainer = document.querySelector('.social-icons-container');
    const moveSocialIconsHere = document.querySelector('.moveSocialIconsHere');

    var copylink = document.querySelector('#copylink');

    const body = document.querySelector('body');
    const darkModeSwitch = document.querySelector('#darkModeSwitch');
    const objectCorners = document.querySelector('#objectCorners');
    const objectWhiteCorners = document.querySelector('#objectWhiteCorners');

    const defaultLocale = "en";
    let locale = defaultLocale;
    let translations = {};

    const currentLocale = sessionStorage.getItem('lang') || defaultLocale;
    const currentLangBtn = document.querySelector(`.langBtn[data-lang="${currentLocale}"]`);

    var qrcodeContainer = document.querySelector('#qrcode');
    var qrcodeHiResContainer = document.querySelector('#qrcodeHiRes');

    let flag = true;

    function inputFieldListener(inputField, shortenBtn) {
        if (inputField) {
            inputField.addEventListener('input', function () {
                if (this.value.trim() !== '') {
                    shortenBtn.removeAttribute('disabled');
                } else {
                    shortenBtn.setAttribute('disabled', 'disabled');
                }
            });
        }
    };

    function isValidHttpUrl(url) {

        url = url.replace(/\s/g, "");

        const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/\S*)?$/;

        console.log('regex check URL valid:', regex.test(url));
        return regex.test(url);
    }

    function validateUrlInput(inputField) {
        const inputValue = inputField.value;
        if (inputValue.trim() === 'http://' || inputValue.trim() === 'https://') {
            console.log("Invalid URL. Please enter a valid HTTP URL. PAM ");
            if (errorBlock && httpsErrorBlock) {
                errorBlock.style.display = 'none';
                httpsErrorBlock.style.display = 'block';
            }
            return false;
        }
        if (!isValidHttpUrl(inputValue)) {
            console.log("Invalid URL. Please enter a valid HTTP URL.");
            if (errorBlock && httpsErrorBlock) {
                httpsErrorBlock.style.display = 'none';
                errorBlock.style.display = 'block';
            }
            return false;
        } return true;
    }

    //theme-dark
    function toggleObjectVisibility() {
        if (qrLinkE) {
            if (body.classList.contains('theme-dark')) {
                if (qrLinkE.style.display == 'flex') {
                    qrLinkE.classList.add('theme-dark-qrcode');
                    objectCorners.style.display = 'none';
                    objectWhiteCorners.style.display = 'flex';
                }
                else {
                    qrLinkE.classList.remove('theme-dark-qrcode');
                    objectCorners.style.display = 'flex';
                    objectWhiteCorners.style.display = 'none';
                }
            } else {
                qrLinkE.classList.remove('theme-dark-qrcode');
                objectCorners.style.display = 'flex';
                objectWhiteCorners.style.display = 'none';
            }
        }
    }

    function updateQRCodeContainers(shortUrlQR) {
        const qrLink = document.querySelector('.qrLink');
        const qrLinkContainer = document.querySelector('.qrLinkContainer');

        // Update original QR-code
        const originalQrCodeContainer = qrLink.querySelector('.qrLink__QRCode');
        createQRCode(originalQrCodeContainer, shortUrlQR);

        // Update cloned QR-code
        const clonedQrCodeContainer = qrLinkContainer.querySelector('.qrLink__QRCode');
        if (clonedQrCodeContainer) {
            createQRCode(clonedQrCodeContainer, shortUrlQR);
        }
    }

    //Request to and response from API 
    function requestAPI(longUrl) {

        if (!longUrl.startsWith('http://') && !longUrl.startsWith('https://')) {
            longUrl = 'https://' + longUrl;
        }

        if (isGoogleDriveLink(longUrl)) {
            longUrl = convertGoogleDriveLink(longUrl);
        } else if (isDropboxLink(longUrl)) {
            longUrl = convertDropboxLink(longUrl);
        }

        const proxyUrl = 'https://wal.ee/proxy.php';

        fetch(proxyUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'url': longUrl
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Request failed with status:' + response.status);
                }
                return response.json();
            })
            .then(data => {

                if (shareBoxDisplayStyle === 'none') {
                    shareBox.style.display = 'block';
                    qrLinkE.style.display = "flex";
                    form1.reset();
                    moveSocialIcons(socialIconContainer, moveSocialIconsHere);
                }
                if (qrLinkContainerE.style.display === 'flex') {
                    qrLinkE.style.display = "none";
                }

                console.log(data.url.url);
                document.querySelector('#origlink').innerHTML = data.url.url;
                document.querySelector('#origlink').setAttribute('href', data.url.url);
                copylink.value = data.shorturl;
                document.querySelector('#titlelink').value = data.title;
                document.querySelector('#qrShortLink').innerHTML = data.shorturl;
                
                updateQRCodeContainers(data.shorturl);

                document.querySelector('#annotation').value = '';
                updateSocialMediaLinks();

                updateShortUrlDisplay(data.shorturl);
            })
            .catch(error => {
                console.log('Error:', error);
            });
    };

    //process after submit clicked
    function processSubmit(form, inputField, shortenBtn) {
        return function (e) {
            e.preventDefault();
            var longUrl = inputField.value;

            if (!validateUrlInput(inputField)) {
                form.reset();
                shortenBtn.setAttribute('disabled', 'disabled');
                form1.style.display = 'block';
                shareBox.style.display = 'none';
                moveSocialIcons(moveSocialIconsHere, socialIconContainer);
                const clonedQrLink = document.querySelector('.clonedQrLink');
                flag = true;
                if (clonedQrLink) {
                    clonedQrLink.remove();
                }
                qrLinkContainerE.style.display = 'none';

                if (body.classList.contains('theme-dark')) {
                    qrLinkE.classList.add('theme-dark-qrcode');
                    objectCorners.style.display = 'none';
                    objectWhiteCorners.style.display = 'flex';
                }
                return;
            }

            errorBlock.style.display = 'none';
            httpsErrorBlock.style.display = 'none';

            if (longUrl) {
                requestAPI(longUrl);
            }
            shortenBtn.setAttribute('disabled', 'disabled');
        };
    }

    document.addEventListener('DOMContentLoaded', function () {
        setLocale(defaultLocale);
        bindLocaleSwitcher();

        //theme-dark
        if (localStorage.getItem('yourls-theme') === 'dark') {
            body.classList.add('theme-dark');
            darkModeSwitch.checked = true;
            if (qrLinkE) {
                qrLinkE.classList.add('theme-dark-qrcode');
                objectCorners.style.display = 'none';
                objectWhiteCorners.style.display = 'flex';
            }
        }

        if (darkModeSwitch) {
            darkModeSwitch.addEventListener('change', function () {
                if (this.checked) {
                    body.classList.add('theme-dark');
                    toggleObjectVisibility();
                    localStorage.setItem('yourls-theme', 'dark');
                } else {
                    body.classList.remove('theme-dark');
                    toggleObjectVisibility();
                    localStorage.setItem('yourls-theme', 'light');
                }
            });
        }

        //url sumbit
        if (form1) {
            inputFieldListener(inputField1, shortenBtn1);
            form1.addEventListener('submit', processSubmit(form1, inputField1, shortenBtn1));
        }

        if (printBtn) {
            printBtn.addEventListener('click', printButtonClick);
        }

        window.addEventListener('afterprint', cleanUpAfterPrint);
    });

    //Languages 
    async function setLocale(newLocale) {
        if (newLocale === locale) return;
        try {
            const newTranslations = await fetchTranslationsFor(newLocale);
            locale = newLocale;
            translations = newTranslations;
            translatePage();
            console.log("Switching to locale:", newLocale);
        } catch (error) {
            console.error("Error fetchig translations", error)
        }
    }

    async function fetchTranslationsFor(newLocale) {
        const response = await fetch(`lang/${newLocale}.json`);
        if (!response.ok) {
            throw new Error(`Failed to fetch translation for ${newLocale}`);
        }
        return await response.json();
    }

    function translatePage() {
        Array.from(document.querySelectorAll("[data-i18n]")).forEach(translateElement);
    }

    function translateElement(element) {
        const key = element.getAttribute("data-i18n");
        const translation = translations[key];
        if (key === 'inputText' || key === 'inputAnnotation') {
            element.setAttribute('placeholder', translation);
        } else if (key == 'origURL') {
            element.textContent = translation;
        } else {
            element.innerHTML = translation;
            element.value = translation;
        }
    }

    function bindLocaleSwitcher() {
        const langBtns = document.querySelectorAll(".langBtn");
        langBtns.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                console.log("Clicked on language button");
                const lang = btn.getAttribute("data-lang");
                setLocale(lang);
                sessionStorage.setItem('lang', lang);

                langBtns.forEach((btn) => {
                    btn.parentElement.classList.remove('is-active');
                });
                btn.parentElement.classList.add('is-active');

                if (window.location.href.includes("about.html")) {
                    document.querySelector('.logo-link').setAttribute('href', '/');
                }
            });
        });
    }

    if (currentLangBtn) {
        currentLangBtn.parentElement.classList.add('is-active');
    }
    setLocale(currentLocale);

    // QRcode

    function clearQRCode() {

        while (qrcodeContainer.firstChild) {
            qrcodeContainer.removeChild(qrcodeContainer.firstChild);
        }
        while (qrcodeHiResContainer.firstChild) {
            qrcodeHiResContainer.removeChild(qrcodeHiResContainer.firstChild);
        }
    }

    function createQRCode(container, shortUrlQR) {
        console.log('Creating QR Code for:', shortUrlQR);

        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }

        new QRCode(container, {
            text: shortUrlQR,
            width: 512,
            height: 512,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
        });

        const dataURLHiRes = container.querySelector('canvas').toDataURL();
        const saveQRCodeHQ = document.getElementById('saveQRCodeHQ');

        saveQRCodeHQ.setAttribute('href', dataURLHiRes);
    }

    //Print Button Functions

    const moveContent = (qrLinkC, qrLinkContainer) => {
        console.log('Button clicked');
        const qrLink = document.querySelector(qrLinkC);
        const qrContainer = document.querySelector(qrLinkContainer);

        if (flag) {
            const clonedContent = qrLink.cloneNode(true);
            clonedContent.classList.add('clonedQrLink');
            clonedContent.classList.remove('qrLink');
            qrContainer.insertBefore(clonedContent, qrContainer.firstChild);
            qrLink.style.display = "none";
            qrContainer.style.display = 'flex';

            flag = !flag;
            console.log(flag);
        } else {
            const annotationTextarea = document.querySelector('#annotation').value;

            const printHeader = document.createElement('div');
            printHeader.classList.add('printHeader');

            ['Sludinājums', 'Объявление', 'Advertisement'].forEach(word => {
                const wordElement = document.createElement('p');
                wordElement.classList.add('word');
                wordElement.textContent = word;
                printHeader.appendChild(wordElement);
            });

            const textFromTextarea = document.createElement('div');
            textFromTextarea.id = 'textFromTextarea';

            textFromTextarea.textContent = annotationTextarea;

            qrContainer.insertBefore(textFromTextarea, qrContainer.firstChild);
            qrContainer.insertBefore(printHeader, qrContainer.firstChild);

            window.print();
        }
    }

    const printButtonClick = (e) => {
        e.preventDefault();
        
        if (body.classList.contains('theme-dark')) {
            qrLinkE.classList.remove('theme-dark-qrcode');
            objectCorners.style.display = 'flex';
            objectWhiteCorners.style.display = 'none';
        }

        moveContent('.qrLink', '.qrLinkContainer');
    };

    function cleanUpAfterPrint() {
        const printHeader = document.querySelector('.printHeader');
        const textFromTextarea = document.querySelector('#textFromTextarea');

        if (printHeader) {
            printHeader.remove();
        }
        if (textFromTextarea) {
            textFromTextarea.remove();
        }
    }

    //move social icons
    function moveSocialIcons(fromDiv, toDiv) {
        while (fromDiv.firstChild) {
            toDiv.appendChild(fromDiv.firstChild);
        }
    }

    //create a new link to share on social media
    function updateSocialMediaLinks() {
        let urlShare = 'https://www.wal.ee';
        qrShortLink = document.querySelector('#qrShortLink');

        console.log(qrShortLink.innerHTML);

        if (qrShortLink && qrShortLink.innerHTML) {
            urlShare = qrShortLink.innerHTML;
        }

        const text = encodeURIComponent('');

        document.querySelector('.fa-facebook').parentElement.href = `https://www.facebook.com/sharer/sharer.php?u=${urlShare}&quote=${text}`;
        document.querySelector('.fa-x-twitter').parentElement.href = `https://twitter.com/intent/tweet?text=${text}&url=${urlShare}`;
        document.querySelector('.fa-pinterest-p').parentElement.href = `https://pinterest.com/pin/create/button/?url=${urlShare}&description=${text}`;
        document.querySelector('.fa-telegram').parentElement.href = `https://t.me/share/url?url=${urlShare}&text=${text}`;
    }

    function isGoogleDriveLink(url) {
        return url.includes('drive.google.com/file/d/');
    }

    function convertGoogleDriveLink(url) {
        const match = url.match(/\/file\/d\/(.*?)\/view/);
        if (match && match[1]) {
            return `https://drive.google.com/uc?export=download&id=${match[1]}`;
        }
        return url;
    }

    function updateShortUrlDisplay(shortUrl) {
        let qrLinkBottom = document.querySelector('.qrLink__bottom');

        if (shortUrl.length > 20) {
            qrLinkBottom.style.bottom = '-3.3em'; 
            qrLinkBottom.style.width = '100%'; 
        } else {
            qrLinkBottom.style.bottom = '-0.9em'; 
            qrLinkBottom.style.width = '70%'; 
        }
    }

    const isDropboxLink = function (url) {
        return url.includes('dropbox.com/s');
    };

    const convertDropboxLink = function (url) {
        if (url.includes('dl=0')) {
            return url.replace('dl=0', 'dl=1');
        } else if (!url.includes('dl=1')) {
            return url + (url.includes('?') ? '&dl=1' : '?dl=1');
        }
        return url;
    };


})();